import { BillingFormType, DeliveryFormType, UserFormType } from '@/types/types';
import { atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { CartType } from '@/types/types';

/**
 * Cart
 */
export const mixpanelUserIdentityAtom = atomWithStorage<string | null>(
  'mixpanelUserIdentity',
  null
);
export const shopifyCheckoutIdWithStorageAtom = atomWithStorage<string | null>(
  'shopifyCheckoutId',
  null
);
export const hubspotDealIdAtom = atomWithStorage<string | null>(
  'hubspotDealId',
  null
);
export const shopifyDraftOrderIdAtom = atomWithStorage<string | null>(
  'shopifyDraftOrderId',
  null
);

export const isCartSlideOutOpenAtom = atom<boolean>(false);
export const cartAtom = atom<CartType | null>(null);
export const hubspotDealAtom = atom<any | null>(null);
export const cartItemsQuantityAtom = atom((get) => {
  const cart = get(cartAtom);
  if (!cart) return 0;
  return cart.lineItems.reduce((acc, item) => acc + item.quantity, 0);
});

/**
 * Cart slide out loaders
 */

export const isCartSlideOutLoadingAtom = atom<boolean>(false);
export const isRemovingOneItemAtom = atom<boolean | string | number>(false);
export const isAddingOneAtom = atom<boolean | string | number>(false);
export const isRemovingLineItemAtom = atom<boolean | string | number>(false);

/**
 * Purchase journey forms
 */
export const userFormAtom = atomWithStorage<UserFormType | null>(
  'userForm',
  null
);
export const deliveryFormAtom = atomWithStorage<DeliveryFormType | null>(
  'deliveryForm',
  null
);
export const billingFormAtom = atom<BillingFormType | null>(null);
export const billingSameAsDeliveryAtom = atom<string>('same');
export const isSoleProprietorAtom = atomWithStorage<boolean>(
  'isSoleProprietor',
  true
);

export const cartStore = createStore();
