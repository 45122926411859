import Client from 'shopify-buy';
const isBrowser = typeof window !== 'undefined';

// First, check that Shopify variables are set
const hasShopify =
  process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID &&
  process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN;

// Warn the client if variables are missing
if (!hasShopify && isBrowser) {
  console.warn('Shopify .env variables are missing');
}

export default Client.buildClient({
  domain: `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_ID}.myshopify.com`,
  storefrontAccessToken:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN || '',
  apiVersion: '2024-01',
});
