export const token = assertValue(
  process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  'Missing environment variable: NEXT_PUBLIC_MIXPANEL_TOKEN'
);

export const domain = assertValue(
  process.env.NEXT_PUBLIC_MIXPANEL_COOKIE_DOMAIN,
  'Missing environment variable: NEXT_PUBLIC_MIXPANEL_COOKIE_DOMAIN'
);

function assertValue<T>(v: T | undefined, errorMessage: string): T {
  if (v === undefined) {
    throw new Error(errorMessage);
  }

  return v;
}
